import { ref, computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { RecroomPerk } from '/~rec/core/perk'
import { RecroomPerkRedeemed } from '/~rec/core/perk-redeemed'
import { useRecProfile } from '/~rec/composables/profile'
import modal from '/~/core/mdl'
import { useLocalization } from '/~/composables/localization'

const { profile } = useRecProfile()
const { translate } = useLocalization()

const loading = ref(false)
const perk = ref<PerkType<typeof isAvailablePerk.value>>()
const perkId = ref('')
const perkStatus = ref('')
const redeemed = ref(false)
const redeemedStatus = ref('')

const types = {
  pending_approval: {
    label: `${translate('common.awaiting')} approval`,
    theme: 'bg-orange-100',
    textColor: 'text-orange-800',
    brand: 'gold-light',
  },
  approved_completed: {
    label: 'Redeemed',
    theme: 'bg-green-100',
    textColor: 'text-green-800',
    brand: 'green-light',
  },
  fulfilled: {
    label: 'Fulfilled',
    theme: 'bg-green-100',
    textColor: 'text-green-800',
    brand: 'green-light',
  },
  active: {
    label: 'Available',
    theme: 'bg-sky-100',
    textColor: 'text-sky-800',
    brand: 'blue-light',
  },
}

type Types = keyof typeof types

type PerkType<T extends boolean> = T extends true
  ? RecroomPerk
  : RecroomPerkRedeemed

const status = computed(() => {
  return perk.value ? types[perk.value.status as Types] : ''
})

const isAvailablePerk = computed(() => {
  return perkStatus.value === 'available'
})

const perkRedeemDisabled = computed(() => {
  const profileTokenBalance = profile.tokenBalance ?? 0

  return perk.value?.cost > profileTokenBalance
})

async function init() {
  loading.value = true
  perk.value = undefined
  try {
    if (isAvailablePerk.value) {
      perk.value = await RecroomPerk.fetchById(perkId.value)
    } else {
      perk.value = await RecroomPerkRedeemed.fetchById(perkId.value)
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

function perkDate(date: string | number) {
  return formatDate('daymonthyearnumeric', date)
}

function gotIt() {
  redeemed.value = false
  redeemedStatus.value = ''
}

function openRequestModal() {
  modal.show('rec-perk-request-approval', {
    props: {
      perk: perk.value,
    },
    on: {
      redeemed: (isApprovalAutomatic) => {
        redeemed.value = true
        redeemedStatus.value = isApprovalAutomatic
          ? 'Your perk has been redeemed!'
          : 'Your request has been sent!'
      },
    },
  })
}

export const usePerk = () => ({
  loading,
  perk,
  redeemed,
  redeemedStatus,
  perkRedeemDisabled,
  status,
  perkStatus,
  perkId,
  isAvailablePerk,
  openRequestModal,
  init,
  perkDate,
  gotIt,
})
