<script>
import ui from '/~/core/ui'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'perk-redeemed',
  components: {
    BaseIcon,
    BaseButton,
    BaseAvatar,
  },
  props: {
    perk: {
      type: Object,
      default: null,
    },
    redeemedStatus: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      ui,
    }
  },
  methods: {
    gotIt() {
      this.$emit('submit')
    },
  },
}
</script>

<template>
  <div class="space-y-2.5">
    <base-icon class="mx-auto my-0" svg="v3/success" :size="250" />

    <h2
      class="w-full text-center text-eonx-neutral-800"
      :class="{
        'text-base leading-6': ui.mobile,
      }"
    >
      {{ redeemedStatus }}
    </h2>

    <div class="flex flex-col">
      <div
        class="flex h-6 flex-wrap content-center"
        :class="{
          'mt-[30px]': ui.desktop,
          'mt-[15px]': ui.mobile,
        }"
      >
        <base-icon
          class="mr-2.5 text-eonx-neutral-800"
          svg="rec/happy-heart-eyes"
          :size="24"
        />
        <div class="float-left">
          <span class="font-bold text-eonx-neutral-800">Perk</span>
        </div>
      </div>

      <div
        class="mt-2.5 rounded-xl p-[5px] xxs:p-[15px]"
        :class="{
          'bg-white': ui.desktop,
          'bg-gray-50': ui.mobile,
        }"
      >
        <div class="flex items-center">
          <div class="mr-[5px] h-10 w-10 shrink-0 xxs:mr-2.5 xxs:h-12 xxs:w-12">
            <base-avatar v-if="perk" class="h-full w-full" :src="perk.logo" />
          </div>
          <div
            :class="{
              truncate: ui.desktop,
            }"
          >
            <div class="truncate font-bold leading-tight text-eonx-neutral-800">
              {{ perk && perk.name }}
            </div>
            <div class="flex flex-wrap leading-tight">
              <div
                class="mr-2.5 flex items-center truncate text-eonx-neutral-600"
                :class="{
                  'float-left': ui.desktop,
                }"
              >
                <base-icon
                  svg="symbion/token"
                  :size="16"
                  class="mr-[5px] shrink-0 text-amber-400"
                />

                <div class="flex-1 truncate text-amber-400">
                  {{ perk && perk.cost + ' Points' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="grid w-full justify-items-center"
        :class="{
          'absolute bottom-0 left-0 mb-5 w-full px-5': ui.desktop,
          'fixed bottom-0 left-0 z-20 w-full bg-white px-5 pb-[15px] pt-5':
            ui.mobile,
        }"
      >
        <base-button
          class="my-0"
          :class="{
            'w-48': ui.desktop,
            'w-full': ui.mobile,
          }"
          @click="gotIt()"
        >
          Got it
        </base-button>
      </div>
    </div>
  </div>
</template>
